@import "@/assets/scss/style.scss";

.dropdown {
    width: 300px;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.radioGroup {
    align-self: center;
}

.rowWrapper {
    display: flex;
    flex-wrap: nowrap;
    height:150px;

    & > * {
        flex: 1 0 50%;
        padding: 0 50px;
    }
}

.submitButton {
    margin: 20px auto 0;
    display: block;
    width: 120px;
}

