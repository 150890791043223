@import "@/assets/scss/style.scss";

.c-cms-show {
    padding-left: 20px;
    padding-right: 20px;

    width: fit-content;
    margin: 16px auto;
    border-radius: 10px;
    background-color: $white-two;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 10px;

    &__total {
        font-family: 'Roboto-Medium', 'Arial', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: inline-block;
        vertical-align: middle;
    }
    &__total-hold {
        flex: none;
        order: 0;
        flex-grow: 1;
        height: 65px;
        line-height: 55px;
    }
    &__table {
        width: 70vw;
    }
}
.c-cms-search {
    display: block;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    margin-top: 20px;
    width: 72vw;
    height: 40px;

    &__filter {
        cursor: pointer;
    }

    &__filter-icon {
        margin-right: 10px;
    }

    &__filter-title {
        color: $black;
    }
    & /deep/ .ant-input-suffix {
        // width: 5%;
        right: 6px;
        // top: 16px;
        height: 80%;
        .ant-btn {
            height: 100%;
            background: $grey-three;
            border: none;
        }
        .ant-input-search-icon {
            display: none;
        }
    }
    & /deep/ .ant-input-prefix {
        opacity: 0.65;
    }
    & /deep/ .ant-input-search {
        width: 98%;
        margin-right: auto;
        height: 40px;
        .ant-input {
            width: 100%;
        }
    }
    & /deep/ .ant-input-group-addon:last-child {
        padding: 4px 11px;
        border: 1px solid $grey-1;
        border-radius: 2px;
        width: 5%;
        min-width: 80px;
    }
}

.c-cms-show-list {
    &__single-list {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        text-transform: capitalize;
    }

    &__search {
        margin-bottom: 16px;
    }

    &__image-wrapper {
        margin-right: 16px;

        img {
            border-radius: 3px;
            width: 50px;
        }
    }
}

.ant-list-item-meta {
    align-items: center;
}

::v-deep .users__table-row {
    cursor: pointer;
}

::v-deep .ant-table-content {
    overflow-y: auto;
}
